import { createAction, createAsyncAction, createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

export const getCalendarData = createAsyncFormAction(
  CONSTANTS.GET_CALENDAR_DATA
)
export const getCalendarMeta = createAsyncFormAction(
  CONSTANTS.GET_CALENDAR_META
)
export const refreshCalendar = createAsyncFormAction(CONSTANTS.REFRESH_CALENDAR)

export const selectCalendarUsers = (form, ids) =>
  createAction(CONSTANTS.SELECT_CALENDAR_USERS, { ids }, form)
export const selectCalendarEventTypes = (form, ids) =>
    createAction(CONSTANTS.SELECT_CALENDAR_EVENT_TYPES, { ids }, form)

export const changeActiveDate = (form, { date }) =>
  createAction(CONSTANTS.CHANGE_ACTIVE_DATE, { date }, form)

export const changeCalendarView = (form, { view }) =>
  createAction(CONSTANTS.CHANGE_CALENDAR_VIEW, { view }, form)

export const getCustomerContacts = createAsyncFormAction(
  CONSTANTS.GET_CUSTOMER_CONTACTS
)

export const clearActivitiesData = form =>
  createAction(CONSTANTS.CLEAR_ACTIVITIES_DATA, {}, form)

export const toggleInterfaceView = (form, { view }) =>
  createAction(CONSTANTS.TOGGLE_INTERFACE_VIEW, { view }, form)

export const taskCompleted = createAsyncFormAction(CONSTANTS.TASK_COMPLETED)
