import { createReducer, getIn, setIn, splice } from 'utils'
import { fromJS, Map, List } from 'immutable'
import { find } from 'lodash'
import * as CONSTANTS from './constants'

const initialState = fromJS({
  activeDate: new Date(),
  calendarView: 'month'
  // date: new Date()
})

const activeUsersKey = 'activeUsers'
const activeDateKey = 'activeDate'
const activitiesKey = 'tile.data.activities'
const activityActivitiesKey = 'tile.data.activities.activities'
const calendarViewKey = 'calendarView'

const tasksDataKey = 'tile.data.activities.tasksData'
const tasksKey = 'tile.data.activities.tasks'
const usersKey = 'tile.data.users'
const eventTypesKey = 'eventTypes'
const eventOptionsKey = 'tile.data.eventOptions'

const dataIdKey = 'dataId'
const availTaskTypesKey = 'tile.data.availableUserTasksTypes'

const eventTypeOptions = 'EventModal.eventTypeOptions'
const taskTypeOptions = 'EventModal.taskTypeOptions'

const behaviors = {
  [CONSTANTS.INIT_CALENDAR]: (state, { payload }) => {
    let result = state
    result = setIn(result, activeDateKey, payload.date)
    result = setIn(
      result,
      'userTaskType',
      getIn(result, availTaskTypesKey)
        .first()
        .get(dataIdKey)
    )
    const userIdx = getIn(result, usersKey).findIndex(
      x => x.get(dataIdKey) === payload.id
    )
    let user = getIn(result, usersKey).find(
      x => x.get(dataIdKey) === payload.id
    )
    user = user.set('isSelected', true)

    result = setIn(
      result,
      usersKey,
      splice(getIn(result, usersKey), userIdx, 1, user)
    )
    result = setIn(result, activeUsersKey, payload.id)
    result = setIn(result, eventTypesKey, payload.selectedEventOptions)
    return result
  },
  [CONSTANTS.UPDATE_ACTIVITY_DATA]: (state, { payload }) => {
    /* ACTUALLY DON'T THINK WE NEED THIS REDUCER AT ALL, THANKFULLY */
    let result = state
    let activity = getIn(result, activityActivitiesKey).find(
      a => a.get(dataIdKey) === payload.dataId
    )

    /* if we are editing an existing activity */
    if (activity) {
      const activityIndex = getIn(result, activityActivitiesKey).findIndex(
        a => a.get(dataIdKey) === payload.dataId
      )
      for (const [k, v] of Object.entries({ payload })) {
        /* we need to adjust the taskType and eventType data because the shape of the data coming back from the API is not the same as it is in the store */
        if (k !== 'eventType' && k !== 'taskType') {
          activity = activity.set(k, v)
        } else {
          if (k === 'taskType') {
            const value = getIn(state, taskTypeOptions).find(
              o => o.dataId === v
            )
            activity = activity.set(k, value)
          }
          if (k === 'eventType') {
            /* there is some weird stuff with the colors for tasks */
            const value = getIn(state, eventTypeOptions).find(
              o => o.dataId === v
            )
            const color =
              v === 'T'
                ? {
                  r: 255,
                  g: 160,
                  b: 122
                }
                : value.color
            activity = activity.set('color', color)
            activity = activity.set(k, value)
          }
        }
      }
      result = setIn(
        result,
        activityActivitiesKey,
        splice(getIn(result, activityActivitiesKey), activityIndex, 1, activity)
      )
    } else {
      const activities = getIn(result, activityActivitiesKey)
      const newActivity = {}
      for (const [k, v] of Object.entries({ payload })) {
        if (k !== 'eventType' && k !== 'taskType') {
          newActivity[k] = v
        } else {
          if (k === 'taskType') {
            const value = getIn(state, taskTypeOptions).find(
              o => o.dataId === v
            )
            newActivity[k] = value
          }
          if (k === 'eventType') {
            const value = getIn(state, eventTypeOptions).find(
              o => o.dataId === v
            )
            const color =
              v === 'T'
                ? {
                  r: 255,
                  g: 160,
                  b: 122
                }
                : value.color
            newActivity[k] = value
            newActivity.color = color
          }
        }
      }
      result = setIn(
        result,
        activityActivitiesKey,
        activities.insert(activities.size, fromJS(newActivity))
      )
    }
    return result
  },
  [CONSTANTS.TASK_COMPLETED.SUCCESS]: (state, { payload }) => {
    let result = state
    // const key = activityActivitiesKey
    // replace with server data here
    // payload.activities
    // payload.tasksData
    result = setIn(result, activityActivitiesKey, fromJS(payload.activities))
    result = setIn(result, tasksDataKey, payload.tasksData)
    result = setIn(result, tasksKey, payload.tasks)
    // result = setIn(result, key, getIn(result, key).filter(x => x.get(dataIdKey) !== payload.id))
    return result
  },
  [CONSTANTS.SELECT_USERS]: (state, { payload: { ids } }) => {
    let result = state
    const selectedUsers = ids.split(',')
    /* Looks like we do not need to do this */
    /* this gets handled in REFRESH_ACTIVITIES.SUCCESS */
    let users = getIn(result, usersKey).toJS()
    users = users.reduce((acc, next) => {
      acc = acc.concat({
        ...next,
        isSelected: selectedUsers.includes(next.dataId)
      })
      return acc
    }, [])
    result = setIn(result, usersKey, fromJS(users))

    result = setIn(result, activeUsersKey, ids)
    result = setIn(result, 'tile.data.userIds', ids)

    return result
  },
  [CONSTANTS.SELECT_EVENT_TYPES]: (state, { payload: { ids } }) => {
    let result = state
    const selectedEventTypes = ids.split(',')

    /* Looks like we do not need to do this */
    /* this gets handled in REFRESH_ACTIVITIES.SUCCESS */
    let eventOptions = getIn(result, eventOptionsKey).toJS()
    eventOptions = eventOptions.reduce((acc, next) => {
      acc = acc.concat({
        ...next,
        isSelected: selectedEventTypes.includes(next.dataId)
      })
      return acc
    }, [])
    result = setIn(result, eventOptionsKey, fromJS(eventOptions))

    result = setIn(result, eventTypesKey, ids)
    return result
  },
  [CONSTANTS.UPDATE_ACTIVITY.SUCCESS]: (state, { payload }) => {
    let result = state
    const key = activitiesKey
    // result = setIn(result, key, fromJS({ payload }))
    result = setIn(result, activityActivitiesKey, fromJS(payload.activities))
    result = setIn(result, tasksDataKey, payload.tasksData)
    result = setIn(result, tasksKey, payload.tasks)
    return result
  },
  [CONSTANTS.SELECT_USER_TASK_TYPE]: (state, { payload: { taskType } }) => {
    let result = state
    result = setIn(result, 'userTaskType', taskType)
    return result
  },
  [CONSTANTS.CHANGE_ACTIVE_DATE]: (state, { payload: { date } }) => {
    let result = state
    result = setIn(result, activeDateKey, date)
    return result
  },
  [CONSTANTS.CHANGE_CALENDAR_VIEW]: (state, { payload }) => {
    let result = state
    const { date, view } = payload
    result = setIn(result, activeDateKey, date)
    result = setIn(result, calendarViewKey, view)
    return result
  },
  [CONSTANTS.REFRESH_ACTIVITIES.SUCCESS]: (state, { payload }) => {
    let result = state
    result = setIn(result, activitiesKey, fromJS(payload.activities))
    result = setIn(result, usersKey, fromJS(payload.users))
    result = setIn(result, eventOptionsKey, fromJS(payload.eventOptions))

    result = setIn(result, 'tile.data.userIds', fromJS(payload.userIds))
    return result
  },
  [CONSTANTS.GET_ACTIVITY_TOOLTIP.SUCCESS]: (state, { payload }) => {
    let result = state
    const taskData = getIn(result, tasksDataKey)
    const activityData = getIn(result, activityActivitiesKey)
    const tasks = taskData && List.isList(taskData) ? taskData.toJS() : []
    const activities =
      activityData && List.isList(activityData) ? activityData.toJS() : []
    const isTask = find(tasks, { dataId: payload.title.id }) || null

    if (isTask) {
      result = setIn(
        result,
        tasksDataKey,
        fromJS(
          tasks.reduce((acc, next) => {
            acc =
              next.dataId === payload.title.id
                ? acc.concat({
                  ...next,
                  tooltip: payload
                })
                : acc.concat(next)
            return acc
          }, [])
        )
      )
    } else {
      result = setIn(
        result,
        activityActivitiesKey,
        fromJS(
          activities.reduce((acc, next) => {
            acc =
              next.dataId === payload.title.id
                ? acc.concat({
                  ...next,
                  tooltip: payload
                })
                : acc.concat(next)
            return acc
          }, [])
        )
      )
    }
    return result
  }
}

export default createReducer(initialState, behaviors)
